




















































import { Component, Vue } from "vue-property-decorator";
import AppButton from "@/components/core/AppButton.vue";
import AppInput from "@/components/core/AppInput.vue";
import AppInputGroup from "@/components/core/AppInputGroup.vue";
import AppLabel from "@/components/core/AppLabel.vue";
import AppWidget from "@/components/core/AppWidget.vue";
import TheLogoWithText from "@/components/TheLogoWithText.vue";
import TheVerticalCenter from "@/components/TheVerticalCenter.vue";
import { sendResetPasswordEmail } from "@/utils/authentication";
import * as notification from "@/utils/notification";

@Component({
  components: {
    AppLabel,
    AppInputGroup,
    AppInput,
    AppButton,
    AppWidget,
    TheLogoWithText,
    TheVerticalCenter,
  },
})
export default class ForgotPassword extends Vue {
  notification: notification.Notification | null = null;
  loading = false;
  emailAddress = "";

  async sendPasswordResetEmail(): Promise<void> {
    try {
      this.loading = true;
      await sendResetPasswordEmail(this.emailAddress);
      this.notification = await notification.createSuccessNotification(
        "Success! An email is on its way."
      );
    } catch (err) {
      this.notification = await notification.createErrorNotification(
        err.message
      );
    } finally {
      this.loading = false;
    }
  }
}
